import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import {
  CounsellingWrapper,
  CounsellingImage,
  CounsellingPageTitle,
  CounsellingDetails,
} from "./style"

interface CounsellingProps {}

const Counselling: React.FunctionComponent<CounsellingProps> = props => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/wheat-field.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 400, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `)

  return (
    <CounsellingWrapper>
      <CounsellingDetails>
        <CounsellingPageTitle>
          <b>Frequently Asked Questions</b>
        </CounsellingPageTitle>

        <Image fluid={Data.avatar.childImageSharp.fluid} />
        <br />

        <h3>How can counselling help me?</h3>

        <ul>
          <li>To support you to explore and understand your experiences</li>

          <li>
            To assist you to develop new skills, resources and coping strategies
          </li>

          <li>To provide non-judgemental support</li>

          <li>To help you set personal and professional goals</li>
        </ul>

        <h3>What type of counselling do you offer?</h3>

        <p>
          Behavioural, CBT, Cognitive, Integrative, Person centred, Relational,
          Solution focused brief therapy, Transactional analysis, Creative
          therapy, Solution focussed.
        </p>

        <h3>What type of people do you work with?</h3>

        <p>Adults, Older adults, Organisations.</p>

        <h3>Can anyone make an appointment?</h3>
        <p>
          Yes, anyone is welcome to make an appointment for any issue they would
          like to have support for. Appointments can be made by using the
          contact form.
        </p>
        <h3>How does it work?</h3>
        <p>
          The initial meeting of a free of charge 20 minutes consultation to
          ensure that firstly, I am the right counsellor to help you and for you
          to decide whether you want to proceed with counselling. This can be
          over the telephone, virtual, face to face, or audio. It is
          also an opportunity to assess the best way to help you move forward to
          facilitate a safe trusting client counsellor relationship. In the
          event you decide to continue, further sessions will last for 1 hour.
        </p>
        <p>
          I will send you the necessary forms to be completed prior to your
          first session. These may be returned by email or post and I ask they
          are returned prior to commencing your subsequent counselling session.
        </p>
        <h3>What if I cannot make it to my appointment?</h3>
        <p>
          Please call or email as early as possible to let me know that you
          cannot make your appointment. I understand sometimes that things can
          happen unexpectedly, I appreciate 24 hours notice of a cancellation.
          If there is time I may be able to offer the appointment to someone
          else.
        </p>
        <h3>What is the venue like?</h3>
        <p>
          I am located in a private, quiet residential area close to public
          transport.
        </p>
        <h3>How much does it cost?</h3>
        <p>Costs are from £55.00 per hour consultation</p>
      </CounsellingDetails>
    </CounsellingWrapper>
  )
}

export default Counselling
