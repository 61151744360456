import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Counselling from "../containers/Counselling"
import SEO from "../components/seo"

const CounsellingPage = (props: any) => {
  const { data } = props

  return (
    <Layout>
      <SEO
        title="Counselling & Therapy services near Bury St Edmunds"
        description={data.site.siteMetadata.description}
      />
      <Counselling />
    </Layout>
  )
}

export default CounsellingPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
